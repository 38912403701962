
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import type { ReportingMessagesStatsFullLine } from '@/definitions/reporting/messagesCalls/messages/types'

export default defineComponent({
  components: {
    PageContent,
  },
  setup() {
    const statsFull = ref<ReportingMessagesStatsFullLine[]>([
      { year: 2013, sent: '1,445', received: '45', deliveryRate: '51.91%', replyRate: '1.62%', costs: '$ 993.00' },
      { year: 2014, sent: '2,491', received: '91', deliveryRate: '78.57%', replyRate: '8.13%', costs: '$ 138.00' },
      { year: 2015, sent: '1,223', received: '23', deliveryRate: '45.94%', replyRate: '2.52%', costs: '$ 441.00' },
      { year: 2016, sent: '1,445', received: '99', deliveryRate: '52.22%', replyRate: '7.37%', costs: '$ 451.00' },
      { year: 2018, sent: '639', received: '26', deliveryRate: '94.08%', replyRate: '4.76%', costs: '$ 180.00' },
      { year: 2019, sent: '826', received: '312', deliveryRate: '98.39%', replyRate: '6.42%', costs: '$ 1,670.00' },
      { year: 2020, sent: '6,316', received: '93', deliveryRate: '55.68%', replyRate: '6.37%', costs: '$ 448.00' },
      { year: 2021, sent: '893', received: '31', deliveryRate: '64.19%', replyRate: '2.97%', costs: '$ 397.00' },
      { year: 2022, sent: '757', received: '57', deliveryRate: '11.57%', replyRate: '6.55%', costs: '$ 217.00' },
    ])

    return {
      statsFull,
    }
  },
})
